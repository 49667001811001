import {
  PLUGIN_OPERATIONS,
  DIRECT_PLUGIN,
} from "@openedx/frontend-plugin-framework";
import CourseAppTranslation from "@edx/course-app-translation-plugin";
import AITranslationsUIComponent from '@edx/ai-translations-studio-ui-plugin';
import { DatadogLoggingService } from '@edx/frontend-logging';

// Load environment variables from .env file
const config = {
  ...process.env,
  AI_TRANSLATIONS_URL: 'https://ai-translations.stage.edx.org',
  loggingService: DatadogLoggingService,
  pluginSlots: {
    additional_course_plugin: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: "whole-course-translation-plugin",
            type: DIRECT_PLUGIN,
            priority: 1,
            RenderWidget: CourseAppTranslation,
          },
        },
      ],
    },
    additonal_translations_component_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'additonal_translations_component_slot',
            type: DIRECT_PLUGIN,
            priority: 1,
            RenderWidget: AITranslationsUIComponent,
          },
        },
      ],
    },
  },
};

export default config;
